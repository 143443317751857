const patientsInfo = [
    {
        title: "Appointments",
        descriptions: [
            "If your appointment is other than a standard consultation, please tell the receptionist when you make an appointment with your doctor so that extra time can be allowed. Your doctor may require a longer consultation for e.g. women’s health, pap smear, medical assessment, or an initial visit for a work-related injury or motor vehicle accident, medical report, pre-employment examination or a procedure.",
            "Your doctor may have “Quick Clinics” for repeat scripts and update referrals from 12 midday to 12.30pm and these appointment are 5 minutes only appointments Monday to Friday.  These appointments can be over the telephone or in person. If you have any other issues to discuss, the doctors consulting here request that you please book a standard appointment with your GP."
        ],
        items: [
        ]
    },
    {
        title: "Cancellations and Non Attendance",
        descriptions: [
            "The doctors operating from GPs on Vermont Medical Centre require 2 hours notification of a cancellation or a Failure to Attend Fee of $30 will be incurred and payment will be required before another appointment can be booked. Appointments prior to 11am cannot be cancelled, please consider this before booking."
        ],
        items: [
        ]
    },
    {
        title: "Test Results",
        descriptions: [
            "The doctors operating from GPs on Vermont Medical Centre do not provide test results over the phone under any circumstance."
        ],
        items: [
            "Normal results – you will not be contacted. You can book an appointment with your doctor at your convenience.",
            "Abnormal result - you will be contacted by the Practice Nurse or Practice Manager on behalf of your doctor and advised to make a follow-up appointment with your doctor.",
            "Urgent result – you will be contacted by the Practice Nurse or Practice Manager on behalf of your doctor and an appointment with your doctor will be made within the next 24 hours."
        ] 
    },
    {
        title: "My Health - Record",
        descriptions: [
            "Having a My Health record means your important health information like allergies, current conditions and treatments, medicine details, pathology reports or diagnosis imaging scan reports can be digitally stored in one place. Healthcare providers like doctors, specialist and hospital staff can see these details online from anywhere at any time when they need to, such as in an accident or emergency. Please advise your doctor at your appointment if you want this information to be uploaded."
        ],
        items: [
        ]

    }
]

export default patientsInfo;